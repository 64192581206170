import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import PlayIcon from '../../assets/images/play_icon.svg'
import SliderControls from '../sliderControls/sliderControls.js'
import breakpoints from '../../styles/mediaQuery/mediaQuery.js'
import BlurUp from '../blurUp/blurUp'
import Button from '../button/button.js'
import './toolImageSlider.sass'

class ToolImageSlider extends React.Component {
  constructor(props) {
    super(props)

    this.tileIsActive = this.tileIsActive.bind(this)
    this.state = {
      activeSlide: 0,
      data: [...this.props.images],
    }
  }

  goToSlide(index, dontAnimate) {
    if (!this.slider) return
    this.slider.slickGoTo(index, dontAnimate)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.breakpoint !== this.props.breakpoint) {
      this.goToSlide(0, true)
    }
  }

  getPaginateBy() {
    switch (this.props.breakpoint) {
      case 'xxs':
      case 'xs':
      case 's':
      case 'm':
      case 'l':
        return 1
      default:
        return 2
    }
  }

  tileIsActive(index) {
    const paginateBy = this.getPaginateBy()
    const currentDotIndex = Math.floor(this.state.activeSlide / paginateBy)
    const lastTileIndex = this.props.images.length - 1
    const isOnCurrentDot = currentDotIndex === Math.floor(index / paginateBy)
    const isOnLastDot =
      currentDotIndex === Math.floor(lastTileIndex / paginateBy)

    return (
      (this.props.images.length - index <= paginateBy && isOnLastDot) ||
      isOnCurrentDot
    )
  }

  onInactiveImageClick(event, index) {
    event.preventDefault()
    const paginateBy = this.getPaginateBy()
    const currentDotIndex = Math.floor(this.state.activeSlide / paginateBy)
    const targetDotIndex = Math.floor(index / paginateBy)
    if (targetDotIndex > currentDotIndex) {
      this.goToSlide(this.state.activeSlide + paginateBy)
    } else {
      this.goToSlide(this.state.activeSlide - paginateBy)
    }
  }

  render() {
    let className = 'tool-image-slider'
    if (this.props.className) className += ` ${this.props.className}`

    if (this.props.loading) {
      className += ' tool-image-slider--loading'
      return (
        <div className={className}>
          <div className="slick-list">
            <div className="tool-image-slider__slide" />
          </div>
        </div>
      )
    }

    if (this.props.images.length === 1) {
      className += ' tool-image-slider--single-image'
    }

    const settingsSlides = {
      dots: false,
      arrows: false,
      infinite: false,
      fade: false,
      draggable: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      responsive: [
        {
          breakpoint: breakpoints.xl - 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    return (
      <div className={className}>
        <Slider ref={c => (this.slider = c)} {...settingsSlides}>
          {this.state.data.map((media, index) => {
            const isActive = this.tileIsActive(index)
            return (
              <button
                onClick={event => {
                  !isActive && this.onInactiveImageClick(event, index)
                  isActive &&
                    this.props.onImageClick &&
                    this.props.onImageClick(index)
                }}
                className="tool-image-slider__slide"
                key={`slide${index}`}
              >
                <BlurUp
                  mode="animated"
                  className={`tool-image-slider__media${
                    isActive ? ' tool-image-slider__media--active' : ''
                  } objectfit-cover objectfit-position-top`}
                  src={media.url}
                  alt={media.title}
                />
                {this.props.onImageClick && (
                  <Button
                    appearance="primary"
                    className="tool-image-slider__slide-button"
                    icon={PlayIcon}
                  >
                    Start Presentation
                  </Button>
                )}
              </button>
            )
          })}
        </Slider>

        <SliderControls
          goToSlide={this.goToSlide.bind(this)}
          totalSlides={this.state.data.length}
          activeSlide={this.state.activeSlide}
          paginateBy={this.getPaginateBy()}
        />
      </div>
    )
  }
}

ToolImageSlider.propTypes = {
  loading: PropTypes.bool,
  images: PropTypes.array,
  onImageClick: PropTypes.func,
}

// const mapStateToProps = state => ({
//   breakpoint: state.breakpoint.breakpoint,
// })

export default ToolImageSlider
