import React from 'react'
import cx from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'
import sampleSize from 'lodash/sampleSize'
import ToolTile from '../toolTile/toolTile'
import ToolTileGrid from '../toolTile/toolTileGrid'
import { mapMethod } from '../../utils/mapMethodQueryResults'

type Props = {
  className?: string
  methodSlug: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ToolDetailsRelated: React.FC<Props> = ({ methodSlug, className }) => {
  const data = useStaticQuery<Queries.DetailsRelatedQuery>(graphql`
    query DetailsRelated {
      directus {
        method {
          name
          logo {
            ...imageFragment
          }
          headline
          categories {
            category_id {
              slug
            }
          }
          tags
          slug
          participants_from
          participants_to
          duration_from
          duration_to
        }
      }
    }

    fragment imageFragment on DirectusData_directus_files {
      id
      imageFile {
        publicURL
      }
    }
  `)

  const details = React.useMemo<Method[]>(
    () =>
      sampleSize(
        data.directus.method.filter(x => x.slug !== methodSlug),
        5
      ).map(mapMethod),
    [data]
  )

  return (
    <div className={cx('tool-details-related', className)}>
      <span className="tool-details-related__headline h3">
        Explore similar methods
      </span>
      <ToolTileGrid>
        {details.map(method => (
          <ToolTile
            key={method.slug}
            link={`/methods/${method.slug}/`}
            title={method.name}
            iconUrl={method.thumb}
            category={method.categories[0]}
            summary={method.headline}
            participants={method.participants}
            duration={method.duration}
            tags={method.tags}
          />
        ))}
      </ToolTileGrid>
    </div>
  )
}

export default ToolDetailsRelated
