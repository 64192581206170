import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import { AppContext } from '../state/appState'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'
import Section from '../components/section/section'
import ToolImageSlider from '../components/toolImageSlider/toolImageSlider'
import {
  ToolDetailsDescription,
  ToolDetailsFeatures,
  ToolDetailsMeta,
  ToolDetailsRelated,
  ToolDetailsSummary,
  ToolDetailsContact,
} from '../components/toolDetails'
import Lightbox from '../components/toolLightbox/toolLightbox'
import Divider from '../components/divider/divider'
import mapMethodQueryResults from '../utils/mapMethodQueryResults'

const breadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'Methods', href: '/methods' },
]

const Method: React.FC<PageProps<
  Queries.MethodPageQuery,
  Queries.MethodPageQueryVariables
>> = ({ data, location: { href } }) => {
  const [{ breakpoint }] = useContext<any>(AppContext)
  const openLightbox = useRef<(index: number) => void>()
  const [method] = useMemo<Method[]>(() => mapMethodQueryResults(data), [data])
  const images = method.gallery.map(url => ({ title: '', url }))
  const handleAfterInit = useCallback<
    (options: { openModal: (index: number) => void }) => void
  >(
    ({ openModal }) => {
      openLightbox.current = openModal
    },
    [openLightbox]
  )
  const handleImageClick = useCallback(
    (pos: number) => {
      openLightbox.current?.(pos)
    },
    [openLightbox]
  )
  const handleRequestPresentationStart = useCallback(() => {
    openLightbox.current?.(0)
  }, [openLightbox])

  if (!method) {
    return null
  }

  return (
    <DefaultLayout
      className="page-category"
      hasBanner={false}
      seo={{ title: method.name }}
    >
      <div className="tool-details-wrapper">
        <Section className="breadcrumbs-section">
          <Breadcrumbs breadcrumbs={[...breadcrumbs, { title: method.name }]} />
        </Section>
        <Section className="tool-details-image-section">
          <ToolImageSlider
            onImageClick={handleImageClick}
            images={images}
            breakpoint={breakpoint}
          />
          <Lightbox afterInit={handleAfterInit} images={images} />
        </Section>
        <Section className="tool-details-summary-section">
          <ToolDetailsSummary
            categories={method.categories}
            difficulty={method.difficulty}
            extraTags={method.extraTags}
            iconUrl={method.thumb}
            onRequestPresentationStart={handleRequestPresentationStart}
            subtitle={method.headline}
            tags={method.tags}
            title={method.name}
            websiteLink={method.download}
          />
        </Section>
        <Section className="tool-details-details-section">
          <ToolDetailsDescription
            description={method.summary}
            className="tool-details-details-subsection tool-details-details-subsection__description"
          />

          <Divider
            orientation="horizontal"
            className="tool-details-details-subsection tool-details-details-subsection-horizontal-divider hide-from-m"
          />
          <Divider
            orientation="vertical"
            className="tool-details-details-subsection-vertical-divider hide-to-m"
          />
          <ToolDetailsFeatures
            tags={method.steps}
            className="tool-details-details-subsection tool-details-details-subsection__features"
          />
          <Divider
            orientation="horizontal"
            className="tool-details-details-subsection tool-details-details-subsection-horizontal-divider hide-from-m"
          />
          <Divider
            orientation="vertical"
            className="tool-details-details-subsection-vertical-divider hide-to-m"
          />

          <ToolDetailsMeta
            tools={method.tools}
            className="tool-details-details-subsection tool-details-details-subsection__meta"
            url={href}
            resources={method.resources}
          />
        </Section>
        <Section className="tool-details-related-section" hideX>
          <ToolDetailsContact methodName={method.name} />
          <ToolDetailsRelated methodSlug={method.slug} />
        </Section>
      </div>
    </DefaultLayout>
  )
}

export const query = graphql`
  query MethodPage($slug: String!) {
    directus {
      method(filter: { slug: { _eq: $slug } }) {
        id
        name
        headline
        tools {
          tool_id {
            name
            icon {
              id
              imageFile {
                publicURL
              }
            }
            link
          }
        }
        categories {
          category_id {
            slug
          }
        }
        figjam
        extra_tags
        gallery {
          directus_files_id {
            id
            imageFile {
              publicURL
            }
          }
        }
        slug
        summary
        steps {
          content
        }
        tags
        logo {
          id
          imageFile {
            publicURL
          }
        }
        difficulty
        resources {
          resource_id {
            icon {
              id
              imageFile {
                publicURL
              }
            }
            name
            link
            author
          }
        }
      }
    }
  }
`

export default Method
