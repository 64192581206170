import React from 'react'
import PropTypes from 'prop-types'

import './sliderControls.sass'

class ToolsSliderControls extends React.Component {
  render() {
    if (this.props.totalSlides / this.props.paginateBy <= 1) return ''

    const totalDots = Math.ceil(this.props.totalSlides / this.props.paginateBy)
    const activeDot = Math.ceil(this.props.activeSlide / this.props.paginateBy)

    const loading = this.props.loading
    const className = `slider__controls${
      loading ? ' slider__controls--loading' : ''
    } ${this.props.className || ''}`
    const ButtonTag = loading ? 'div' : 'button'

    return (
      <div className={className}>
        <ButtonTag
          className="slider__arrow slider__arrow--left"
          disabled={activeDot === 0}
          onClick={
            loading
              ? () => {}
              : this.props.goToSlide.bind(
                  this,
                  Math.max(this.props.activeSlide - this.props.paginateBy, 0)
                )
          }
          aria-label="Previous slide"
        >
          {/* <ReactSVG src="../../static/icons/chevronRight.svg" /> */}
        </ButtonTag>
        <div className="slider__dots-container">
          <ul className="slider__dots">
            {Array.apply(null, Array(totalDots)).map((dot, index) => (
              <li
                key={`dot${index}`}
                className={`slider__dot-container${
                  Math.abs(activeDot - index) <= 3 ||
                  (index < 7 && activeDot < 7 - 3) ||
                  (index >= totalDots - 7 && activeDot >= totalDots - (7 - 3))
                    ? ''
                    : ' slider__dot-container--hidden'
                }`}
              >
                <ButtonTag
                  className={`slider__dot${
                    activeDot === index ? ' slider__dot--active' : ''
                  }`}
                  onClick={
                    loading
                      ? () => {}
                      : this.props.goToSlide.bind(
                          this,
                          index * this.props.paginateBy
                        )
                  }
                  aria-label={`Slide ${index + 1}`}
                />
              </li>
            ))}
          </ul>
        </div>
        <ButtonTag
          className="slider__arrow slider__arrow--right"
          disabled={activeDot === totalDots - 1}
          onClick={
            loading
              ? () => {}
              : this.props.goToSlide.bind(
                  this,
                  Math.min(
                    this.props.activeSlide + this.props.paginateBy,
                    this.props.totalSlides - 1
                  )
                )
          }
          aria-label="Next slide"
        >
          {/* <ReactSVG src="../../static/icons/chevronRight.svg" /> */}
        </ButtonTag>
      </div>
    )
  }
}

ToolsSliderControls.propTypes = {
  loading: PropTypes.bool,
  goToSlide: PropTypes.func,
  activeSlide: PropTypes.number.isRequired,
  totalSlides: PropTypes.number.isRequired,
  paginateBy: PropTypes.number.isRequired,
}

export default ToolsSliderControls
