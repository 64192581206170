import React, { Component } from 'react'
import { oneOf } from 'prop-types'
import './divider.sass'

class Divider extends Component {
  render() {
    const className = 'divider-container ' + (this.props.className || '')
    const subClassName = `divider divider-${this.props.orientation}`

    return (
      <div className={className}>
        <div className={subClassName} />
      </div>
    )
  }
}

Divider.propTypes = {
  orientation: oneOf(['vertical', 'horizontal']),
}

Divider.defaultProps = {
  orientation: 'horizontal',
}

export default Divider
