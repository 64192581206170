import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from 'react-tippy'
import Tag from '../tag/tag.js'
import Button from '../button/button.js'
import BlurUp from '../blurUp/blurUp.js'
import externalLinkIcon from '../../assets/images/external_link.svg'
import figmaIcon from '../../assets/images/figma_icon.png'
import figmaIcon2x from '../../assets/images/figma_icon_2x.png'
import PlayIcon from '../../assets/images/play_icon.svg'

import './toolDetails.sass'
import { trackEvent } from '../../utils/gtag.js'

const ToolDetailsSummary = ({
  categories,
  className = '',
  difficulty,
  extraTags,
  iconUrl,
  onRequestPresentationStart,
  subtitle,
  tags,
  title,
  websiteLink,
}) => (
  <div
    className={`tool-details-summary tool-details-summary__wrapper ${className}`}
  >
    <div className="tool-details-summary__container">
      <div className="tool-details-summary__top-tags-container">
        <Tag
          className="tool-details-summary__tag"
          link={`/search/?q=${difficulty}`}
          appearance="white"
        >
          {difficulty}
        </Tag>
        {categories.map(category => (
          <Tag
            key={category}
            className="tool-details-summary__tag tool-details-summary__free-tag"
            appearance={category === 'warm-up' ? 'yellow' : 'blue'}
            link={`/categories/${category}`}
          >
            {category}
          </Tag>
        ))}
      </div>
      <div className="tool-details-summary__sub-container tool-details-summary__first_sub-container">
        <BlurUp
          role="presentation"
          className="tool-details-summary__icon"
          src={iconUrl}
        />
        <div className="tool-details-summary__title-container">
          <span className="tool-details-summary__title h1 h--decor">
            {title}
          </span>
          <span className="tool-details-summary__subtitle">{subtitle}</span>
        </div>
      </div>
      <div className="tool-details-summary__sub-container tool-details-summary__second_sub-container">
        <div className="tool-details-summary__tags-container">
          {tags.map((category, i) => (
            <Tag
              key={i}
              className="tool-details-summary__tag"
              nolink="true"
              appearance="purple"
            >
              {category}
            </Tag>
          ))}
          {extraTags.slice(0, 3).map((category, i) => (
            <Tag
              key={i}
              className="tool-details-summary__tag"
              link={`/search/?q=${category}`}
            >
              {category}
            </Tag>
          ))}
          {extraTags.length > 3 ? (
            <Tooltip
              html={
                <div className="tool-details-summary__tag-tooltip">
                  {extraTags.slice(3).map((category, i) => (
                    <Tag
                      key={i}
                      className="tool-details-summary__tag"
                      link={`/search/?q=${category}`}
                    >
                      {category}
                    </Tag>
                  ))}
                </div>
              }
              disabled={false}
              position="bottom"
              trigger="mouseenter"
              animation="fade"
              animateFill={false}
              interactive={true}
              arrow={true}
              hideOnClick={false}
              distance={16}
              className="tool-details-summary__tag-tooltip"
            >
              <Tag className="tool-details-summary__tag" nolink="true">
                +{extraTags.length - 3}
              </Tag>
            </Tooltip>
          ) : null}
        </div>
        <div className="tool-details-summary__website-link-container">
          {websiteLink.figjam && (
            <Button
              tag="a"
              href={websiteLink.figjam}
              target="_blank"
              rel="noreferrer noopener"
              appearance="ghost"
              className="tool-details-summary__griditem--full tool-details-summary__website-link"
              icon={externalLinkIcon}
              onClick={() => {
                window.dispatchEvent(new Event('showsurveymodal'))
                trackEvent('open', {
                  method: title,
                  type: 'figjam',
                })
              }}
            >
              <img
                srcSet={`${figmaIcon} 1x, ${figmaIcon2x} 2x`}
                className="tool-details-summary__website-link-type-icon"
              />
              Open in FigJam
            </Button>
          )}
          {onRequestPresentationStart && (
            <Button
              appearance="primary"
              className="tool-details-summary__griditem--full tool-details-summary__website-link"
              icon={PlayIcon}
              onClick={() => {
                onRequestPresentationStart()
                trackEvent('presentation', {
                  method: title,
                  type: 'start',
                })
              }}
            >
              Start Presentation
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
)

ToolDetailsSummary.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  difficulty: PropTypes.string,
  extraTags: PropTypes.arrayOf(PropTypes.string),
  iconUrl: PropTypes.string,
  onRequestPresentationStart: PropTypes.func,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  websiteLink: PropTypes.object,
}

export default ToolDetailsSummary
