/* View in fullscreen */
export const openFullscreen = async () => {
  /* Get the documentElement (<html>) to display the page in fullscreen */
  var elem = document.documentElement

  if (elem.requestFullscreen) {
    await elem.requestFullscreen()
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    await elem.webkitRequestFullscreen()
  }
}

/* Close fullscreen */
export const closeFullscreen = async () => {
  if (document.exitFullscreen && document.fullscreenEnabled) {
    await document.exitFullscreen()
  } else if (
    document.webkitExitFullscreen &&
    document.webkitFullscreenEnabled
  ) {
    /* Safari */
    await document.webkitExitFullscreen()
  }
}
