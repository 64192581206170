import React from 'react'
import PropTypes from 'prop-types'

class ToolDetailsDescription extends React.Component {
  render() {
    const className = 'tool-details-description ' + (this.props.className || '')
    return (
      <div className={className}>
        <div className="h4">Summary</div>
        <div
          className="tool-details-description__description"
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
      </div>
    )
  }
}

ToolDetailsDescription.propTypes = {
  description: PropTypes.string,
}

export default ToolDetailsDescription
