import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Slider from 'react-slick'
import BlurUp from '../blurUp/blurUp'
import { openFullscreen, closeFullscreen } from '../../utils/fullscreen'
import './toolLightbox.sass'

import ChevronRightBoldIcon from '../../assets/images/chevronRightBold.inline.svg'
import CrossBoldIcon from '../../assets/images/crossBold.inline.svg'

const ToolLightbox = ({ className, afterInit, images }) => {
  const [holdButtons, setHoldButtons] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const [activeSlide, setActiveSlide] = useState(0)
  const sliderRef = useRef(null)

  const activateHoldButtons = useCallback(() => {
    setHoldButtons(true)
  }, [])
  const deactivateHoldButtons = useCallback(() => {
    setHoldButtons(false)
  }, [])
  const openModal = useCallback(
    async imageIndex => {
      setActiveSlide(imageIndex)
      setShowModal(true)
      await openFullscreen()
      setTimeout(() => {
        sliderRef.current?.slickGoTo(imageIndex, true)
      })
    },
    [sliderRef]
  )
  const closeModal = useCallback(async () => {
    await closeFullscreen()
    setShowModal(false)
  }, [setShowModal])
  const handleSlideClick = useCallback(async () => {
    if (activeSlide === images.length - 1) {
      await closeModal()
      return
    }

    sliderRef.current?.slickNext()
  }, [activeSlide, closeModal, sliderRef])
  const handleKeyPress = useCallback(
    event => {
      const code =
        typeof event.keyCode === 'number' ? event.keyCode : event.which
      if (code === 37) {
        sliderRef.current?.slickPrev()
      }
      if (code === 39) {
        sliderRef.current?.slickNext()
      }
    },
    [sliderRef]
  )
  const handleFullscreenChange = useCallback(() => {
    if (
      !document.fullscreenElement &&
      !document.webkitCurrentFullScreenElement
    ) {
      setShowModal(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      )
    }
  }, [])

  useEffect(() => {
    afterInit({ openModal })
  }, [afterInit])

  useEffect(() => {
    let timeout
    const temporarilyShowButtons = () => {
      setShowButtons(true)
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setShowButtons(false)
      }, 3000)
    }

    if (showModal) {
      temporarilyShowButtons()
      window.addEventListener('mousemove', temporarilyShowButtons)

      return () => {
        window.removeEventListener('mousemove', temporarilyShowButtons)
      }
    }
  }, [showModal])

  return (
    <div className={`tool-lightbox ${className || ''}`}>
      <ReactModal
        appElement={
          typeof document === 'undefined'
            ? undefined
            : document.getElementById('__next')
        }
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        className="tool-lightbox-modal"
        overlayClassName="tool-lightbox-overlay"
      >
        <button
          className="tool-lightbox__cover-next-button"
          onClick={handleSlideClick}
          tabIndex={-1}
        />
        <div className="tool-lightbox__slider-wrapper">
          <Slider
            beforeChange={(_, next) => {
              setActiveSlide(next)
            }}
            accessibility
            arrows={false}
            className="tool-lightbox__slider"
            dots={false}
            fade
            infinite={false}
            ref={sliderRef}
            slidesToScroll={1}
            slidesToShow={1}
            speed={500}
            swipe
          >
            {images.map((media, index) => {
              const isActive = index === activeSlide
              return (
                <div
                  onClick={event => {
                    if (
                      !event.target.classList.contains('tool-lightbox__slide')
                    )
                      return
                    closeModal()
                  }}
                  className="tool-lightbox__slide"
                  key={`slide${index}`}
                >
                  <div className="tool-lightbox__image-container">
                    <button
                      className="tool-lightbox__cover-next-button"
                      onClick={handleSlideClick}
                      tabIndex={-1}
                    />
                    <BlurUp
                      loading="loading image"
                      onClick={() => sliderRef.current?.slickGoTo(index)}
                      className={`tool-lightbox__image${
                        isActive ? ' tool-lightbox__image--active' : ''
                      }`}
                      src={media.url}
                      alt={media.title}
                    />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        {images.length > 1 && (
          <button
            className={`tool-lightbox__button tool-lightbox__arrow-button tool-lightbox__arrow-button--prev${
              showButtons || holdButtons
                ? ' tool-lightbox__button--visible'
                : ''
            }`}
            onClick={sliderRef.current?.slickPrev}
            onMouseEnter={activateHoldButtons}
            onMouseLeave={deactivateHoldButtons}
            disabled={activeSlide === 0}
            aria-label="Go to previous slide"
            tabIndex={showButtons || holdButtons ? undefined : -1}
          >
            <ChevronRightBoldIcon className="icon" />
          </button>
        )}
        {images.length > 1 && (
          <button
            className={`tool-lightbox__button tool-lightbox__arrow-button tool-lightbox__arrow-button--next${
              showButtons || holdButtons
                ? ' tool-lightbox__button--visible'
                : ''
            }`}
            onClick={sliderRef.current?.slickNext}
            onMouseEnter={activateHoldButtons}
            onMouseLeave={deactivateHoldButtons}
            aria-label="Go to next slide"
            disabled={activeSlide === images.length - 1}
            tabIndex={showButtons || holdButtons ? undefined : -1}
          >
            <ChevronRightBoldIcon className="icon" />
          </button>
        )}
        <button
          className={`tool-lightbox__button tool-lightbox__close-button${
            showButtons || holdButtons ? ' tool-lightbox__button--visible' : ''
          }`}
          onClick={closeModal}
          onMouseEnter={activateHoldButtons}
          onMouseLeave={deactivateHoldButtons}
          aria-label="Close modal layer"
          tabIndex={showButtons || holdButtons ? undefined : -1}
        >
          <CrossBoldIcon className="icon" />
        </button>
      </ReactModal>
    </div>
  )
}

ToolLightbox.propTypes = {
  images: PropTypes.array,
}

export default ToolLightbox
