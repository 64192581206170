import React from 'react'
import PropTypes from 'prop-types'

const ToolDetailsMeta = ({ tools, resources }) => {
  return (
    <div className={`tool-details-meta tool-details-meta__wrapper`}>
      <span className="tool-details-features__headline h4">What you need</span>
      <ul className="tool-details-tools">
        {tools.map(tool => (
          <li className="tool-details-tools__item" key={tool.name}>
            <div className="tool-details-tools__icon-wrapper">
              {tool.icon && tool.icon ? (
                <img
                  src={tool.icon}
                  alt=""
                  className="tool-details-tools__icon"
                />
              ) : null}
            </div>

            <div>
              {tool.link ? (
                <a
                  href={tool.link}
                  target="_blank"
                  rel="noreferrer"
                  className="tool-details-tools__link"
                >
                  {tool.name}
                </a>
              ) : (
                tool.name
              )}
            </div>
          </li>
        ))}
      </ul>
      {resources && resources.length ? (
        <>
          <span className="tool-details-features__headline h4">
            External resources
          </span>
          <ul className="tool-details-resources">
            {resources.map(resource => (
              <li className="tool-details-resources__item" key={resource.name}>
                {resource.icon ? (
                  <img
                    src={resource.icon}
                    alt=""
                    className="tool-details-resources__icon"
                  />
                ) : null}
                <div className="tool-details-resources__text">
                  {resource.link ? (
                    <a
                      href={resource.link}
                      target="_blank"
                      rel="noreferrer"
                      className="tool-details-resources__link"
                    >
                      {resource.name}
                    </a>
                  ) : (
                    resource.name
                  )}
                  {resource.author ? (
                    <div className="tool-details-resources__author">
                      by {resource.author}
                    </div>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}

ToolDetailsMeta.propTypes = {
  url: PropTypes.string.isRequired,
  tools: PropTypes.array,
}

export default ToolDetailsMeta
