import React from 'react'
import PropTypes from 'prop-types'

class ToolDetailsFeatures extends React.Component {
  render() {
    const className = 'tool-details-features ' + (this.props.className || '')
    return (
      <div className={className}>
        <span className="tool-details-features__headline h4">Step-by-step</span>
        <ol className="tool-details-features__feature-list">
          {this.props.tags.map((tag, i) => (
            <li
              key={i}
              className="tool-details-feature__feature-list-item"
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          ))}
        </ol>
      </div>
    )
  }
}

ToolDetailsFeatures.propTypes = {
  tags: PropTypes.array,
}

export default ToolDetailsFeatures
