import React, { useContext } from 'react'
import { GeoContext } from '../../state/geo'

const ToolDetailsContact = ({ methodName }) => {
  const { url } = useContext(GeoContext)
  return (
    <div className="tool-details-contact">
      <div className="h3 tool-details-contact__header">
        Do you have a question?
      </div>
      <div className="tool-details-contact__text">
        These methods might seem simple, but applying them in a real project is
        another story. We’re there to help. If you have any questions, do not
        hesitate to get in touch with us at the User Experience Strategy &
        Design team.
      </div>
      <a
        href={`mailto:uxsd@${url}group.com?subject=UX Strategy Kit Inquiry to ${methodName}`}
        className="button button--turq button--wide tool-details-contact__button"
      >
        Ask Away!
      </a>
    </div>
  )
}

export default ToolDetailsContact
